import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useInView } from "react-intersection-observer";
import "./animation.css";
import useFetchData from "./useFetchHook";

const TestimonialPage = () => {
  const {
    data: testimonialData,
    loading,
    error,
  } = useFetchData("testimonials/testimonialData.json");

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [testimonials, setTestimonials] = useState([]);
  useEffect(() => {
    if (testimonialData && testimonialData.testimonials) {
      setTestimonials(testimonialData.testimonials);
    }
  }, [testimonialData]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3000); // Change testimonial every 2 seconds

    return () => clearInterval(interval); // Clear interval on unmount
  }, [testimonials.length]);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  // Ensure serviceData is defined and has the services property
  if (!testimonialData || !testimonialData.testimonials) {
    return <Typography variant="h6">No services available</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row", lg: "row" },
        gap: 2,
        p: 8,
        position: "relative",
        bgcolor: "background.paper",
      }}
    >
      <Box
        ref={ref}
        className={inView ? "scale-in-left" : ""}
        sx={{
          flex: 1,
          m: "8%",

          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <img src="/miscellaneousImages/testimonialQuote.png" alt="Image" height={48} width={58} />
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            color: "#1c1c1c",
            lineHeight: 1.2,
          }}
        >
          What Clients say about us
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#1c1c1c",
          }}
        >
          Our clients love us for our dedication to quality, exceptional
          service, and the results that speak for themselves!
        </Typography>
      </Box>

      <Box
        ref={ref}
        className={inView ? "scale-in-right" : ""}
        sx={{
          flex: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 3,
          bgcolor: "rgba(255, 168, 54, 0.8)",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 3,
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "white",
                fontWeight: "bold",
              }}
            >
              What they say
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "white",
                fontSize: "22px",
              }}
            >
              {testimonials[currentIndex]?.comment}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: { xs: "12%", md: "5%", lg: "5%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {testimonials[currentIndex]?.cname}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                }}
              >
                {testimonials[currentIndex]?.designation}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <IconButton
              sx={{
                bgcolor: "background.paper",
                borderRadius: "50%",
              }}
            >
              <ArrowBackIosNewRoundedIcon
                sx={{ color: "#000000" }}
                onClick={handlePrevious}
              />
            </IconButton>
            <IconButton
              sx={{
                bgcolor: "background.paper",
                borderRadius: "50%",
              }}
            >
              <ArrowForwardIosRoundedIcon onClick={handleNext} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialPage;
