import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useForm, ValidationError } from "@formspree/react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Print";
import EmailIcon from "@mui/icons-material/Email";
import { useInView } from "react-intersection-observer";
import "./animation.css";

const MyForm = () => {
  const [selectedServices, setSelectedServices] = useState([]);

  const handleServiceChange = (event) => {
    setSelectedServices(event.target.value);
  };
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [state, handleSubmit] = useForm("xwpednkj");
  if (state.succeeded) {
    return (
      <Stack direction="row" spacing={1} sx={{ margin: "0% 20%" }}>
        <Chip label="Form Submit Successfully " />
      </Stack>
    );
  }

  return (
    <>
      <Box
        ref={ref}
        className={inView ? "slide-in-left" : ""}
        sx={{
          width: "80%",
          // height: 800,
          backgroundColor: "white",
          borderRadius: 10,
          overflow: "hidden",
          position: "relative",
          margin: "2% 10%",
          padding: "4% 0%",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        {/* </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent:'center',
            // py:'10%'
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Montserrat-Bold, Helvetica",
                fontWeight: "bold",
                fontSize: { xs: "28px", sm: "32px", md: "32px", lg: "36px" },
                lineHeight: "normal",
                color: "transparent",
                textAlign: "center",
              }}
            >
              <span style={{ color: "#000000" }}>Get in </span>
              <span style={{ color: "#FFA836" }}>Touch</span>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Montserrat-SemiBold, Helvetica",
                fontWeight: "600",
                color: "#000000",
                fontSize: "0.875rem",
                lineHeight: 1.5,
                textAlign: "center",
                padding: "0% 5%",
              }}
            ></Typography>
          </Box>
          <form
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
              padding: "5%",
              width: "80%",
            }}
          >
            <Box
              sx={{
                padding: { xs: "0% 15%", sm: "0% 15%", md: "0% 15%", lg: "0%" },
                width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
              }}
            >
              <TextField
                fullWidth
                label="Name *"
                name="name"
                id="name"
                variant="outlined"
                style={{
                  padding: {
                    xs: "0px 0px",
                    sm: "2px 0px",
                    md: "6px 0px",
                    lg: "6px 0px",
                  },
                  margin: "6px 0px",
                  fontSize:'19px'
                }}
              />
              <ValidationError
                prefix="name"
                field="name"
                errors={state.errors}
              />

              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                id="email"
                name="email"
                style={{
                  padding: {
                    xs: "0px 0px",
                    sm: "2px 0px",
                    md: "6px 0px",
                    lg: "6px 0px",
                  },
                  margin: "6px 0px",
                  fontSize:'16px'
                }}
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <TextField
                fullWidth
                label="Phone number *"
                name="mobile"
                id="mobile"
                variant="outlined"
                style={{
                  padding: {
                    xs: "0px 0px",
                    sm: "2px 0px",
                    md: "6px 0px",
                    lg: "6px 0px",
                  },
                  margin: "6px 0px",
                }}
              />
              <ValidationError
                prefix="mobile"
                field="mobile"
                errors={state.errors}
              />
              <TextField
                fullWidth
                label="Describe Your Project"
                variant="outlined"
                name="project"
                id="project"
                style={{
                  padding: {
                    xs: "0px 0px",
                    sm: "2px 0px",
                    md: "6px 0px",
                    lg: "6px 0px",
                  },
                  margin: "6px 0px",
                }}
              />
              <ValidationError
                prefix="project"
                field="project"
                errors={state.errors}
              />

              <InputLabel
                htmlFor="serviceValue"
                style={{
                  // display:'block',
                  // width:'100%',
                  padding: {
                    xs: "0px 0px",
                    sm: "2px 0px",
                    md: "6px 0px",
                    lg: "6px 0px",
                  },
                  margin: "6px 0px"
                 
                }}
              >
                What services may we assist you with?*
              </InputLabel>
              <Select
                id="sewrviceValue"
                name="serviceValue"
                value={selectedServices}
                onChange={handleServiceChange}
                multiple
                fullWidth
                style={{
                  padding: {
                    xs: "0px 0px",
                    sm: "2px 0px",
                    md: "6px 0px",
                    lg: "6px 0px",
                  },
                  margin: "6px 0px",
                }}
              >
                <MenuItem value="Select Services">Select Services ...</MenuItem>
                <MenuItem value="sensors" defaultChecked>
                  Sensors
                </MenuItem>
                <MenuItem value="consultation">Consultation</MenuItem>
                <MenuItem value="solutions">Solutions</MenuItem>
                <MenuItem value="services">Services</MenuItem>
              </Select>
              <ValidationError
                prefix="Services"
                field="serviceValue"
                errors={state.errors}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#FFA836",
                  color: "white",
                  fontFamily: "Montserrat-Bold, Helvetica",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "orange",
                  },
                }}
              >
                SEND
              </Button>
            </Box>
          </form>
          <List
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              gap: {xs:1,sm:2,md:1,lg:5},
            }}
          >
            <ListItem>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText
              style={{
                display:'block',
                width:'120px'
              }}
                primary="PHONE"
                secondary="+91-9354833470"
                primaryTypographyProps={{
                  fontFamily: "Montserrat-SemiBold, Helvetica",
                  fontWeight: "600",
                  fontSize: "0.8125rem",
                  lineHeight: 1.5,
                  color: "#000000",
                }}
                secondaryTypographyProps={{
                  fontFamily: "Montserrat-Regular, Helvetica",
                  fontSize: "0.8125rem",
                  lineHeight: 1.5,
                  color: "#FFA836",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FaxIcon />
              </ListItemIcon>
              <ListItemText
                primary="LANDLINE"
                secondary="011-45064343"
                primaryTypographyProps={{
                  fontFamily: "Montserrat-SemiBold, Helvetica",
                  fontWeight: "600",
                  fontSize: "0.8125rem",
                  lineHeight: 1.5,
                  color: "#000000",
                }}
                secondaryTypographyProps={{
                  fontFamily: "Montserrat-Regular, Helvetica",
                  fontSize: "0.8125rem",
                  lineHeight: 1.5,
                  color: "#FFA836",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                primary="EMAIL"
                secondary="info@hscontrolsystems.com"
                primaryTypographyProps={{
                  fontFamily: "Montserrat-SemiBold, Helvetica",
                  fontWeight: "600",
                  fontSize: "0.8125rem",
                  lineHeight: 1.5,
                  color: "#000000",
                }}
                secondaryTypographyProps={{
                  fontFamily: "Montserrat-Regular, Helvetica",
                  fontSize: "0.8125rem",
                  lineHeight: 1.5,
                  color: "#FFA836",
                }}
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  );
};

export default MyForm;
