
import ContactInfo from "../components/ContactInfo";
import MyForm from "../components/MyForm";
import {OurCliente} from "../components/OurClient";
const ContactUs=()=>{
    return(
        <>
<MyForm/>
{/* <ContactInfo/> */}
<OurCliente/>
        </>
    )
}
export default ContactUs;