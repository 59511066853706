import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import ProjectCard from "./ProjectMCard";
import useFetchData from "./useFetchHook";
const ProjectMainPage = () => {
  const {
    data: projectData,
    loading,
    error,
  } = useFetchData("projects/projectData.json");

  if (loading)
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center",height:'100vh' }}
      >
        <img src="miscellaneousImages/loader.png" />
      </Box>
    );
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        my={4}
        sx={{ overflow: "hidden" }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "32px", sm: "36px", md: "56px", lg: "56px" },
            color: "black",
            fontFamily: "Inter-Bold, Helvetica",
            fontWeight: "bold",
          }}
        >
          Project Spotlight
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#5e6c84",
            fontFamily: "Inter-Regular, Helvetica",
            mt: 2,
            textAlign:'center',
            fontSize:'20px'
          }}
        >
          Discover how our projects drive progress, combining creativity and
          technology to deliver impactful results across various sectors
        </Typography>
      </Grid>

      <Grid container spacing={2} sx={{ px: "10%" }}>
        {projectData.projects.map((item, index) => (
          <ProjectCard key={index} data={item} index={index} />
        ))}
      </Grid>
    </>
  );
};

export default ProjectMainPage;
