import Footer from "./components/Footer";
import { BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import CartPage from "./components/cartPage";
import "./App.css";
import BlogDescription from "./components/BlogDescription";
import SearchResults from "./components/SearchedResults";
import { NotFound } from "./components/NotFound";

// website
import Home from "./website/Home";
import About from "./website/About";
import ProductsPage from "./website/ProductPage";
import ProductDescription from "./website/ProductDescription";
import AppDescr from "./website/ApplicationDescription";
import ApplicationPage from "./website/ApplicationPage";
import ProjectDescription from "./website/ProjectDescription";
import ProjectPage from "./website/ProjectPage";
import ContactUs from "./website/ContactUs";
import BlogPage from "./website/BlogPage";
import ServiceP from "./website/ServicePage";
import PostsAndEvents from "./website/PostsAndEvents";
import EventDescription from "./components/eventDescription";
import axios from "axios";
import NewNavbar from "./components/NewNavbar";
import ScrollToTop from "./components/ScrollTop";
import useFetchData from "./components/useFetchHook";

function App() {
  const {
    data: productData,
    loading,
    error,
  } = useFetchData("products/productData.json");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
  }, [debounceTimeout]);
  const handleSearchChange = async (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    // Clear any existing timeout
    if (debounceTimeout) clearTimeout(debounceTimeout);

    const timeoutId = setTimeout(() => {
      if (term) {
        const searchWords = term.split(" ").filter((word) => word); // Split into words and filter out empty strings
        const filteredResults = productData.products.filter((item) => {
          const productName = item.productName.toLowerCase();

          // Check if all search words are included in any of the fields
          return searchWords.every((word) => productName.includes(word));
        });

        setSearchResults(filteredResults);
        setShowModal(true);
      } else {
        setSearchResults([]);
        setShowModal(false);
      }
    }, 500);
    setDebounceTimeout(timeoutId);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <BrowserRouter>
        <NewNavbar
          handleSearchChange={handleSearchChange}
          searchTerm={searchTerm}
        />
        <SearchResults
          show={showModal}
          onHide={handleCloseModal}
          results={searchResults}
        />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" exact element={<ProductsPage />} />
          <Route path="/products/:params" exact element={<ProductsPage />} />
          <Route path="/product-description" element={<ProductDescription />} />
          <Route path="/applications" element={<ApplicationPage />} />
          <Route path="/projects" element={<ProjectPage />} />
          <Route path="/application-description" element={<AppDescr />} />
          <Route path="/project-description" element={<ProjectDescription />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/blog-description/:id" element={<BlogDescription />} />
          <Route path="/services" element={<ServiceP />} />

          {/* <Route path="/events" element={<PostsAndEvents />} />
          <Route path="/cart-page" element={<CartPage />} />
          <Route path="/event-description" element={<EventDescription />} /> */}
           <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
