import React from "react";
import { Modal, ListGroup } from "react-bootstrap";
import ProductCard from "./productCard";
import { Grid } from "@mui/material";

const SearchResults = ({ show, onHide, results }) => {
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Search Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid container columns={{ xs: "4", sm: "4", md: "4", lg: "4" }}>
            {results.length > 0 ? (
              results.map((product, index) => (
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <ProductCard key={index} data={product} />
                </Grid>
              ))
            ) : (
              <ListGroup.Item>No products found.</ListGroup.Item>
            )}
          </Grid>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchResults;
