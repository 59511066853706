import Hero from "../components/Hero";
import AboutSection from "../components/AboutSection";
import { ValuesPage } from "../components/ValuesPage";
import { FaqPage } from "../components/Faq";
import Boxc from "../components/Box";
import NewsCard from "../components/EventPage";
import { OurCliente } from "../components/OurClient";
const About = () => {
  return (
    <>
      <AboutSection />
      <ValuesPage />
      <OurCliente/>
      <Boxc />
      {/* <NewsCard /> */}
      {/* <FaqPage /> */}
    </>
  );
};
export default About;
