import { Box, Grid, Typography, Button } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { BlogCard } from "./BlogCard";
import "./animation.css";
import useFetchData from "./useFetchHook";
import { useEffect, useState } from "react";

const Blogpage = () => {
  const { data, loading, error } = useFetchData("blogs/blogData.json");
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    if (data && data.blogs) {
      setBlogData(data.blogs.slice(0, 3));
    }
  }, [data]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <img src="miscellaneousImages/loader.png" />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <>
      <Box
        sx={{
          py: 6,
          pb: "10%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            px: { xs: 2, sm: 4, md: 6 },
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            textAlign="center"
            my={4}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: "32px", sm: "36px", md: "56px", lg: "56px" },
                color: "black",
                fontFamily: "Inter-Bold, Helvetica",
                fontWeight: "bold",
              }}
            >
              Insight & Stories
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "black",
                fontFamily: "Inter-Regular, Helvetica",
                mt: 2,
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Explore our latest articles, blogs, tips and industry insights to
              stay informed and inspired.
            </Typography>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}
          >
            {blogData.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <BlogCard data={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Blogpage;
