import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "./nav.css";

function NewNavbar({ handleSearchChange, searchTerm }) {
  const [isShrunk, setIsShrunk] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleScroll = debounce(() => {
    const currentScrollY = window.scrollY;

    // Check if scrolling down
    if (currentScrollY > lastScrollY || currentScrollY > 80) {
      setIsShrunk(true); // Scroll down
    } else if (currentScrollY < lastScrollY) {
      // Optionally, keep it shrunk while scrolling up
      // Do nothing, keep it shrunk
      setIsShrunk(false);
    }

    // Update the last scroll position
    setLastScrollY(currentScrollY);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const cat = [
    { name: "G.Re.T.A" },
    { name: "Duct Sensors" },
    { name: "Water Sensors" },
    { name: "Meteo Sensors" },
    { name: "Indoor Sensors" },
    { name: "Software" },
    { name: "Asphalt Status Sensors" },
    { name: "Material Sensors" },
    { name: "Data Loggers" },
    { name: "Signal Conditioning Units" },
    { name: "Accessories" },
    { name: "Masts and Towers" },
    { name: "Display and Controllers" },
    { name: "Portable WBGT Meter" },
  ];

  // const logoStyles = {
  //   width: isShrunk ? "160px" : "160px",
  //   height: isShrunk ? "30px" : "45px",

  // };
  const navbarStyles = {
    transition: "padding 0.3s ease, 0.3s ease",
    backgroundColor: "white",
  };

  return (
    <Navbar
      sticky="top"
      expand="lg"
      style={navbarStyles}
      className={`bg-body-tertiary ${isShrunk ? `py-1` : `py-3`}`}
    >
      <Container fluid>
        <Navbar.Brand href="/" style={{ paddingLeft: "22px" }}>
          <img
            src="/websiteLogo/HS-logo.png"
            alt="logo"
            style={{ width: "160px", height: "45px" }}
          />
          {/* className={`bg-body-tertiary ${isShrunk ? `py-1` : `py-3`}`} */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" style={{ margin: "0% 7%" }}>
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <NavDropdown title="Products" id="navbarScrollingDropdown">
              <NavDropdown.Item as={Link} to="/products">
                All Categories
              </NavDropdown.Item>
              {cat.map((category, index) => (
                <NavDropdown.Item
                  as={Link}
                  to={`/products/${category.name}`}
                  key={index}
                  className="dropdown-item-custom"
                >
                  {category.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>

            <Nav.Link as={Link} to="/applications">
              Applications
            </Nav.Link>
            <Nav.Link as={Link} to="/services">
              Services
            </Nav.Link>

            <Nav.Link as={Link} to="/projects">
              Projects
            </Nav.Link>

            {/* <Nav.Link as={Link} to="/events">
              Events
            </Nav.Link> */}
            <Nav.Link as={Link} to="/blogs">
              Blogs
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About Us
            </Nav.Link>
            <Nav.Link as={Link} to="/contact">
              Contact Us
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="text"
              value={searchTerm}
              placeholder="Search Product"
              onChange={handleSearchChange}
            />
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NewNavbar;
