import {
  Box,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
export const BlogCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <Card
        sx={{
          display: "flex",
          textAlign:'center',
          flexDirection: "column",
          width: "100%",
          maxWidth: 360,
          borderRadius: "12px",
          marginY: 2,
          boxShadow:
            "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        }}
        onClick={() => navigate(`/blog-description/${data.id}`)}
      >
        <CardMedia
          component="img"
          image={`${data.blogImages[0]?.mediaUrl}`}
          alt="image"
          sx={{
            aspectRatio: 2 / 2,
            objectFit: "contain",
            height: "240px",
            padding: "12px",
          }}
        />
        <CardContent>
          <Typography variant="body1" color="textSecondary">
            {data.blogHeading}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {/* You can add description or additional details here */}
          </Typography>
        </CardContent>
        <CardActions>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Button
                sx={{
                  color: "#FFA836",
                  px: 3,
                  m: 2,
                  backgroundColor: "white",
                  border: "1px solid #FFA836",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() => navigate("/blog-description")}
              >
                Read
              </Button>
            </Box>
          </Box>
        </CardActions>
      </Card>
    </>
  );
};