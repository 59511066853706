import React from "react";
import { Card, CardContent, Box, Typography, Grid } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { useInView } from "react-intersection-observer";
import { styled } from "@mui/material/styles";
import "./animation.css";

export const ValuesCard = ({ data }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const ImageContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: "56px", // Adjust height as needed
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  }));

  const IconContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30px", // Adjust size as needed
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    backgroundColor: theme.palette.background.paper, // Ensure icon is visible
    borderRadius: "50%",
  }));

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={4} py={4}>
        <Card
          ref={ref}
          className={inView ? "scale-in-bottom" : ""}
          sx={{
            maxWidth: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
            backgroundColor: "background.paper",
            borderRadius: "16px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          }}
        >
          {/* Image Container with Icon */}
          <ImageContainer>
            <Box
              component="img"
              src="/miscellaneousImages/shape.svg"
              alt="Background"
              sx={{ margin: "0 auto", objectFit: "cover" }}
            />
            <IconContainer>
              <PeopleIcon />
            </IconContainer>
          </ImageContainer>

          {/* Title */}
          <CardContent sx={{ textAlign: "center", px: 1 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "text.primary" }}
            >
              {data.valueHeading}
            </Typography>
          </CardContent>

          {/* Description */}
          <CardContent sx={{ textAlign: "center", px: 0 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {data.valueContent}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
