import React from "react";
import { Typography, Grid } from "@mui/material";
import { ValuesCard } from "./ValuesCard";
import useFetchData from "./useFetchHook";

export const ValuesPage = () => {
  const {
    data: ValuesData,
    loading,
    error,
  } = useFetchData("values/valuesData.json");
  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }
  if (!ValuesData || !ValuesData.valuesdata) {
    return <Typography variant="h6">No services available</Typography>;
  }
  return (
    <Grid
      container
      space={0}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "1% 10%",
        backgroundColor: "white",
      }}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        py={4}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "32px", sm: "36px", md: "46px", lg: "52px" },
            color: "black",
            fontFamily: "Inter-Bold, Helvetica",
            fontWeight: "bold",
          }}
        >
          Why Customers Choose Us ?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#5e6c84",
            fontFamily: "Inter-Regular, Helvetica",
            pt: 2,
            fontSize:'20px',
            textAlign:'center',
          }}
        >
          In order to successfully pursue the corporate mission, We are inspired
          by the following principles:
        </Typography>
      </Grid>
      <Grid container columns={{ xs: 4, sm: 12, md: 12, lg: 12 }} spacing={2}>
        {ValuesData.valuesdata.map((item, index) => (
          <ValuesCard data={item} key={index} />
        ))}
      </Grid>
    </Grid>
  );
};
