import { Grid, Typography, Box } from "@mui/material";
import ServiceCard from "./ServiceCard";
import { useInView } from "react-intersection-observer";
import "./animation.css";
import useFetchData from "./useFetchHook";

const ServicePage = () => {
  const { data: serviceData, loading, error } = useFetchData('service/serviceData.json');
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Handle loading and error states
  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center",height:'100vh' }}
      >
        <img src="miscellaneousImages/loader.png" />
      </Box>
    );
  }

  if (error) {
    return <Typography variant="h6" color="error">Error: {error}</Typography>;
  }

  // Ensure serviceData is defined and has the services property
  if (!serviceData || !serviceData.services) {
    return <Typography variant="h6">No services available</Typography>;
  }

  return (
    <Box>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        py="2%"
        style={{ backgroundColor: "#FFA836" }}
      >
        <Typography
          ref={ref}
          className={inView ? "focus-in-contract-bck" : ""}
          variant="h6"
          sx={{
            fontSize: { xs: "20px", sm: "26px", md: "32px", lg: "36px" },
            color: "white",
            fontFamily: "Inter-Bold, Helvetica",
            fontWeight: "bold",
          }}
        >
          Services Offered By Us
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "whitesmoke",
            fontFamily: "Inter-Regular, Helvetica",
            pt: 4,
            letterSpacing: 2,
            pb: 4,
            px: "10%",
          }}
        >
          At HS CONTROLS AND SYSTEMS, we offer a range of essential services
          designed to support and enhance your operations. Our End-to-End
          Project Execution Services ensure comprehensive management from
          project initiation to completion, delivering seamless and efficient
          solutions tailored to your needs.
        </Typography>
      </Grid>
      <Grid container spacing={2} sx={{ px: "10%", backgroundColor: "white" }}>
        {serviceData.services.map((item) => (
          <ServiceCard key={item.id} data={item} />
        ))}
      </Grid>
    </Box>
  );
};

export default ServicePage;

