import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  CardActions,
  CardMedia,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
const ProjectCard = ({ data }) => {
  const navigate = useNavigate();
  const date = new Date(data.createdAt);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  return (
    <>
      <Grid item>
        <Card sx={{ maxWidth: 345, boxShadow: 3 }}>
          <Box>
            <CardMedia
              component="img"
              style={{
                width: "100%",
                objectFit: "cover",
                height: "200px",
              }}
              image={`${data.projectImages[0].mediaUrl}`}
              alt="Images"
            />
          </Box>
          <Box>
            <CardContent>
              <Typography variant="h6" color="textPrimary">
                {data?.projectName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {truncateText(data?.projectDescription, 200)}
              </Typography>
            </CardContent>
            <CardActions>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: "50px",
                    padding: "10px 20px",
                    mb: 2,
                    backgroundColor: "#FFA836",
                  }}
                  onClick={() =>
                    navigate("/project-description", { state: { data } })
                  }
                >
                  Learn More
                </Button>
              </Box>
            </CardActions>
          </Box>
        </Card>
      </Grid>
    </>
  );
};
export default ProjectCard;
