import { Grid, Typography, Box, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { styled } from "@mui/system";
import useFetchData from "./useFetchHook";

const ImageContainer = styled("div")({
  position: "relative",
  transition: "opacity 0.5s ease-in-out",
  opacity: 1,
  margin: "4% 2%",
});

const ButtonContainer = styled("div")({
  position: "absolute",
  top: "50%",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  transform: "translateY(-50%)",
  padding: "0 12px",
});

export const OurCliente = () => {
  const { data: clientsData, loading, error } = useFetchData('clients/clientsData.json');

  const [clients, setClients] = useState([]);
  useEffect(() => {
    if (clientsData && clientsData.clients) {
      setClients(clientsData.clients);
    }
  }, [clientsData]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;
  const [fade, setFade] = useState(true);

  // Calculate the current set of images
  const currentItems = clients.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < clients.length) {
      setFade(false);
      setTimeout(() => {
        setCurrentPage(currentPage + 1);
        setFade(true);
      }, 500); // Match this duration with the CSS transition duration
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setFade(false);
      setTimeout(() => {
        setCurrentPage(currentPage - 1);
        setFade(true);
      }, 500); // Match this duration with the CSS transition duration
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextPage();
    }, 3000); // Auto-advance every 5000 milliseconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [currentPage]);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <>
      <Grid item container direction="column" alignItems="center">
        <Typography
          variant="h1"
          sx={{
            py: { xs: 3, sm: 4, md: 6, lg: 6 },
            fontSize: { xs: "32px", sm: "36px", md: "46px", lg: "52px" },
            color: "black",
            fontFamily: "Inter-Bold, Helvetica",
            fontWeight: "bold",
            backgroundColor: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Our Clientele
        </Typography>
        <Box
          sx={{
            width: "90%",
            height: "1px",
            backgroundColor: "grey",
          }}
        />
      </Grid>
      <ImageContainer style={{ opacity: fade ? 1 : 0 }}>
        <ButtonContainer>
          <IconButton
            onClick={handlePrevPage}
            disabled={currentPage === 0}
            color="primary"
          >
            <ArrowBack sx={{ color: "orange" }} />
          </IconButton>
          <IconButton
            onClick={handleNextPage}
            disabled={(currentPage + 1) * itemsPerPage >= clientsData.clients.length}
            color="primary"
          >
            <ArrowForward sx={{ color: "orange" }} />
          </IconButton>
        </ButtonContainer>
        <Grid
          container
          py={12}
          px={12}
          spacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
          backgroundColor="white"
          justifyContent="center"
        >
          {currentItems.map((item, index) => (
            <Grid item xs={6} sm={4} md={3} lg={3} key={index}>
              <img
                src={`${item?.mediaUrl}`}
                style={{
                  margin: "8px",
                  objectFit: "contain",
                  display: "block",
                  width: "130px",
                  height: "130px",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </ImageContainer>
    </>
  );
};
