import { useEffect, useRef } from "react";
import { Grid, Box, Typography } from "@mui/material";
import "./animation.css";

const AboutSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((err) => {
        console.log(err);
      });
    }
    return () => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    };
  }, []);

  return (
    <>
      <Box component="section" sx={{ position: "relative" }}>
        <div style={{ textAlign: "center", width: "50%", margin: "1% auto" }}>
          <img
            src="/miscellaneousImages/testimonialQuote.png"
            alt="Quote Start"
            height={20}
            width={30}
            style={{ marginBottom: "10px" }}
          />

          <Typography
            sx={{
              fontFamily: "Inter-Bold, Helvetica",
              fontWeight: 700,
              color: "#000000",
              fontSize: "24px",
              letterSpacing: "0px",
              color: "orange",
              margin: "10px 0", // Adjust margin as needed
            }}
          >
            One accurate measurement is worth a thousand expert opinions.
          </Typography>

          <img
            src="/miscellaneousImages/testimonialQuote.png"
            alt="Quote End"
            height={20}
            width={30}
            style={{
              transform: "rotate(180deg)",
              marginTop: "10px",
              margin: "0 90%",
            }}
          />
        </div>

        {/* Video */}
        <Box
          sx={{
            boxShadow:
              "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
            margin: "5% 20%",
            objectFit: "contain",
            objectPosition: "center",
          }}
        >
          <video
            src="/website_videos/about_video"
            autoPlay
            loop
            style={{
              width: "100%",
              //   height: "100%",
              objectFit: "contain",
              filter: "brightness(0.95)", // Adjust the brightness here
            }}
          />
        </Box>
      </Box>
    </>
  );
};
export default AboutSection;
