import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  ArrowForwardIosOutlined,
  ArrowBackIosOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import useFetchData from "./useFetchHook";

export const HomeProjectPage = () => {
  
  const {
    data: projectData,
    loading,
    error,
  } = useFetchData("projects/projectData.json");
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  const [imagesData, setImagesData] = useState([]); // Initial state is an empty array
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (projectData && projectData.projects) {
      const imagesUrl = projectData.projects.flatMap((project) =>
        project.projectImages.map((image) => image.mediaUrl)
      );
      setImagesData(imagesUrl);
    }
  }, [projectData]);

  // Reset currentIndex when imagesData changes
  useEffect(() => {
    if (imagesData.length > 0) {
      setCurrentIndex(0);
    }
  }, [imagesData]);

  const handlePrevious = () => {
    if (imagesData.length > 0) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? imagesData.length - 1 : prevIndex - 1
      );
    }
  };

  const handleNext = () => {
    if (imagesData.length > 0) {
      setCurrentIndex((prevIndex) =>
        prevIndex === imagesData.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  useEffect(() => {
    if (imagesData.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesData.length);
      }, 5000); // Change image every 5 seconds

      return () => clearInterval(interval); // Clear interval on unmount
    }
  }, [imagesData.length]); // Only set interval if imagesData has items

  const navigate = useNavigate();

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  if (!projectData || !projectData.projects || imagesData.length === 0) {
    return <Typography variant="h6">No projects available</Typography>;
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        position: "relative",
        width: "100%",
        px: { xs: "5%", sm: "10%" },
        my: { xs: "2%", sm: "5%" },
      }}
    >
      {/* Title and Description */}
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        mb={4}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "32px", sm: "36px", md: "56px", lg: "56px" },
            color: "black",
            fontFamily: "Inter-Bold, Helvetica",
            fontWeight: "bold",
          }}
        >
          Projects Spotlight
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#5e6c84",
            fontFamily: "Inter-Regular, Helvetica",
            mt: 2,
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          Discover how our projects drive progress, combining creativity and
          technology to deliver impactful results across various sectors
        </Typography>
      </Grid>

      {/* Image and Content */}
      <Grid
        item
        sx={{
          width: "100%",
          height: { xs: "400px", sm: "540px" },
          position: "relative",
          borderRadius: "26px",
          overflow: "hidden",
          border:'2px solid orange',
          boxShadow:
          "rgba(14, 30, 37, 0.32) 0px 2px 4px 0px, rgba(14, 30, 37, 0.52) 0px 2px 16px 0px",
        }}
      >
        <Grid
          container
          direction="row"
          sx={{ height: "100%", position: "relative" }}
        >
          {/* Background Image with Filter */}
          {imagesData.length > 0 && (
            <Grid
              item
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url('${imagesData[currentIndex]}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                filter: "brightness(70%)",
                transition: "opacity 0.5s ease",
                zIndex: -1,
              }}
            />
          )}

          <Grid
            item
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            sx={{
              height: "100%",
              textAlign: "left",
              color: "white",
              p: 4,
              position: "relative",
              zIndex: 1,
            }}
          >
            <Button
              sx={{
                color: "white",
                p: 1,
                my: 2,
                backgroundColor: "#FFA836",
                "&:hover": {
                  backgroundColor: "#FFA836",
                  opacity: 1,
                },
              }}
              onClick={() => navigate("/projects")}
            >
              View Projects
            </Button>
            <Typography
              variant="h4"
              sx={{ fontFamily: "Inter-Regular, Helvetica" }}
            >
              {/* Discover Our Standout */}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontFamily: "Inter-Regular, Helvetica", fontSize: "21px" }}
            >
             Empowering Innovation Through Collaboration and Creativity
            </Typography>
          </Grid>

          {/* Buttons */}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}
            sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}
          >
            <Grid item>
              <IconButton
                sx={{ borderRadius: "20px", border: "1px solid #c2c7d0" }}
                onClick={handlePrevious}
              >
                <ArrowBackIosOutlined sx={{ color: "#FFA836" }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                sx={{ borderRadius: "20px", border: "1px solid #c2c7d0" }}
                onClick={handleNext}
              >
                <ArrowForwardIosOutlined sx={{ color: "#FFA836" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
