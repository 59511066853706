import Hero from "../components/Hero";
import { FaqPage } from "../components/Faq";
import MyForm from "../components/MyForm";
import TestimonialPage from "../components/TestimonialPage";
import { HomeProjectPage } from "../components/FigmaProjectPage";
import { ValuesPage } from "../components/ValuesPage";
import { Typography, Box, Grid } from "@mui/material";
import HomeProduct from "../components/HomeProduct";
import Blogpage from "../components/BlogPage";
import NewHomeProduct from "../components/NewHomeProduct";

const Home = () => {
  return (
    <>
      <Hero />
      <Box sx={{ pt: 5, textAlign: "center", backgroundColor: "white" }}>
        <Typography variant="h6" color="textSecondary">
          Our Tech Partners around the world
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", paddingX: "5%" }}>
          {[
            "/partnersLogo/c4.png",
            "/partnersLogo/c3.jpg",
            "/partnersLogo/c2.png",
            "/partnersLogo/c1.png",
            "/partnersLogo/c5.jpg",
          ].map((src, index) => (
            <Grid>
              <img
                key={index}
                src={src}
                alt="Company logo"
                style={{
                  width: "120px",
                  height: "120px",
                  aspectRatio: 4 / 3,
                  objectFit: "contain",
                  padding: "4px",
                  // filter: "grayscale(100%)",
                }}
              />
            </Grid>
          ))}
        </Box>
      </Box>
      <ValuesPage />
      {/* <NewHomeProduct /> */}
      <HomeProduct />
      <Blogpage />
      <HomeProjectPage />
      <TestimonialPage />
      <MyForm />
      {/* <FaqPage /> */}
    </>
  );
};
export default Home;
