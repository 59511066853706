import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          rowGap: 4,
        }}
      >
        {" "}
        <img src="/miscellaneousImages/loader.png" />
        <Button
          variant="contained"
          sx={{
            borderRadius: "50px",
            padding: "10px 20px",
            mb: 2,
            margin: "0 auto",
            backgroundColor: "#FFA836",
          }}
          onClick={() => navigate("/")}
        >
          Go Back
        </Button>
        <h1>Page you are looking for not found</h1>
      </Box>
    </>
  );
};
