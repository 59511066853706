import { Grid, Box, Typography } from "@mui/material";

const ModelCard = ({ data }) => {
  return (
    <>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <div
          style={{ border: "1px solid rgba(255, 168, 54)" }}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            boxShadow: "0 8px 8px rgba(0, 0, 0, 0.1)", // Adjust shadow as needed
            borderRadius: "12px",
          
          }}
        >
          <Box
            component="div"
            sx={{
              backgroundImage: `url(${data?.mediaUrl})`, // Replace with actual image URL
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat:'no-repeat',
              height: 100,
                margin:'4px'
            }}
          />
          <div
            style={{
              flexGrow: 1,
              backgroundColor: "white",
              padding: "12px",
            }}
          >
            <Typography variant="body2" gutterBottom>
              Model name : {data?.modelName}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Model Code : {data?.modelCode}
            </Typography>
          </div>
        </div>
      </Grid>
    </>
  );
};
export default ModelCard;
