// MUI
import React from "react";
import {
  Typography,
  Button,
  Box,
  Container,
  Grid,
  Divider,
  ListItem,
  ListItemText,
  IconButton,
  Link,
  Breadcrumbs,
} from "@mui/material";
import {
  Download,
  RemoveCircleOutline,
  AddCircleOutline,
} from "@mui/icons-material";
import TaskIcon from "@mui/icons-material/Task";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";

import ProjectCard from "./FigmaProjectCard";
import ProductCard from "./productCard";
import ApplicationCard from "./applicationCard";
import NewTestCarousal from "./NewTestCarousal";
import useFetchData from "./useFetchHook";

const AppDesc = () => {
  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useFetchData("projects/projectData.json");
  const {
    data: applicationData,
    loading: applicationLoading,
    error: applicationError,
  } = useFetchData("applications/applicationData.json");
  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useFetchData("products/productData.json");

  const location = useLocation();
  const { data } = location.state || {};
  const { params } = useParams();
  console.log(params);

  const [selected, setSelected] = useState("products");

  const [visibleIndex, setVisibleIndex] = useState({
    first: false,
    second: false,
    third: false,
  });

  const handleIconClick = (buttonKey) => {
    setVisibleIndex((prevStates) => ({
      ...prevStates,
      [buttonKey]: !prevStates[buttonKey],
    }));
  };

  if (applicationLoading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (applicationError) {
    return (
      <Typography variant="h6" color="error">
        Error: {applicationError}
      </Typography>
    );
  }
  if (!applicationData || !applicationData.applications) {
    return <Typography variant="h6">No services available</Typography>;
  }

  return (
    <Box
      sx={{
        bgcolor: "white",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <Container sx={{ mt: 8 }}>
          <Typography variant="body2" color="textSecondary">
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                  Home
                </Link>
                <Link underline="hover" color="inherit" href="/website/apps">
                  Applications
                </Link>
                <Typography sx={{ color: "#FFA836" }}>
                  {data.content.heading}
                </Typography>
              </Breadcrumbs>
            </div>
          </Typography>
          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <NewTestCarousal data={data.content.media || []} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="h3" color="textPrimary">
                {data.content.heading}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1" color="textPrimary">
                {data.content.description}
              </Typography>
              <Box sx={{ mt: 4 }}>
                <Box sx={{ mb: 2, pb: 2, borderBottom: "1.5px solid #e0e0e0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Description
                    </Typography>
                    <IconButton onClick={() => handleIconClick("first")}>
                      {visibleIndex.first ? (
                        <RemoveCircleOutline sx={{ color: "#FFA836" }} />
                      ) : (
                        <AddCircleOutline sx={{ color: "#FFA836" }} />
                      )}
                    </IconButton>
                  </Box>
                  {visibleIndex.first === true && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      {data.content?.description}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mb: 2, pb: 2, borderBottom: "1.5px solid #e0e0e0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Key Highlights
                    </Typography>
                    <IconButton onClick={() => handleIconClick("second")}>
                      {visibleIndex.second ? (
                        <RemoveCircleOutline sx={{ color: "#FFA836" }} />
                      ) : (
                        <AddCircleOutline sx={{ color: "#FFA836" }} />
                      )}
                    </IconButton>
                  </Box>
                  {visibleIndex.second === true && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      {/* <div
                  dangerouslySetInnerHTML={{
                    __html: data?.productDescription
                      .replace(/\n/g, '<br/>') // Convert new lines to <br> tags
                      .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') // Convert tabs to spaces
                  }}
                /> */}
                      {data?.applicationHighlights}
                    </Typography>
                  )}
                </Box>
                {/* <Box sx={{ mb: 2, pb: 2, borderBottom: "1.5px solid #e0e0e0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Data Manuals
                    </Typography>
                    <IconButton onClick={() => handleIconClick("third")}>
                      {visibleIndex.third ? (
                        <RemoveCircleOutline sx={{ color: "#FFA836" }} />
                      ) : (
                        <AddCircleOutline sx={{ color: "#FFA836" }} />
                      )}
                    </IconButton>
                  </Box>
                  {visibleIndex.third === true && (
                    <Box>
                      {data?.applicationDatasheets.length > 0
                        ? data?.applicationDatasheets.map((file, index) => (
                            <Box key={index}>
                              <ListItem>
                                <TaskIcon />
                                <ListItemText primary="MW8540" sx={{ ml: 2 }} />
                                <IconButton color="primary" sx={{ mr: -2 }}>
                                  <a
                                    href={file.fileUrl}
                                    download={file.fileName}
                                  >
                                    <Download />
                                  </a>
                                </IconButton>
                              </ListItem>
                            </Box>
                          ))
                        : "No Files Exist"}
                    </Box>
                  )}
                </Box> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            mx: "10%",
            my: 4,
            px: { xs: 2, sm: 3, md: 4, lg: 6 }, // Horizontal padding for different screen sizes
            py: 1, // Vertical padding (top and bottom)
            borderRadius: "60px",
            border: "1px solid transparent",
            backgroundColor: "whitesmoke",
            display: "flex",
            justifyContent: "space-around", // Center buttons horizontally
            gap: 1, // Space between buttons
          }}
        >
          <Button
            sx={{
              borderRadius: "60px",
              border: "none",
              padding: {
                xs: "2px 24px",
                sm: "2px 32px",
                md: "2px 48px",
                lg: "2px 64px",
              },
              backgroundColor: selected === "products" ? "white" : "whitesmoke",
              color: "black",
              "&:hover": {
                backgroundColor:
                  selected === "products" ? "white" : "whitesmoke",
              },
            }}
            onClick={() => setSelected("products")}
          >
            Products
          </Button>
          <Button
            sx={{
              borderRadius: "60px",
              border: "none",
              padding: {
                xs: "2px 24px",
                sm: "2px 32px",
                md: "2px 48px",
                lg: "2px 64px",
              },
              backgroundColor: selected === "projects" ? "white" : "whitesmoke",
              color: "black",
              "&:hover": {
                backgroundColor:
                  selected === "projects" ? "white" : "whitesmoke",
              },
            }}
            onClick={() => setSelected("projects")}
          >
            Projects
          </Button>
          <Button
            sx={{
              borderRadius: "60px",
              border: "none",
              padding: {
                xs: "4px 24px", // Padding for extra-small screens
                sm: "6px 32px", // Padding for small screens
                md: "6px 48px", // Padding for medium screens
                lg: "6px 64px", // Padding for large screens
              },
              backgroundColor:
                selected === "applications" ? "white" : "whitesmoke",
              color: "black",
              "&:hover": {
                backgroundColor:
                  selected === "applications" ? "white" : "whitesmoke",
              },
            }}
            onClick={() => setSelected("applications")}
          >
            More Applications
          </Button>
        </Box>

        {selected === "products" && (
          <Box>
            <Typography
              variant="h4"
              color="textPrimary"
              sx={{ my: 5, textAlign: "center" }}
            >
              Recommended Product
            </Typography>
            <Box
              sx={{
                backgroundColor: "white",
                width: "100%",
                p: { xs: 4, sm: 6, md: 8, lg: 10 },
              }}
            >
              <Grid
                container
                spacing={4}
                sx={{ my: 1, justifyContent: "center" }}
              >
                {productLoading && (
                  <Typography variant="h6">Loading products...</Typography>
                )}

                {productError && (
                  <Typography variant="h6" color="error">
                    Error: {productError}
                  </Typography>
                )}

                {!productLoading && !productError && (
                  <>
                    {data.content?.heading &&
                      productData.products
                        .filter((item) =>
                          item.productApplication.includes(
                            data.content?.heading
                          )
                        ).slice(0,12)
                        .map((item, index) => (
                          <ProductCard key={index} data={item} />
                        ))}
                  </>
                )}

                {/* Optionally handle the case where there are no projects */}
                {!productLoading &&
                  !productError &&
                  productData.products.length === 0 && (
                    <Typography variant="h6">No projects available</Typography>
                  )}
              </Grid>
            </Box>
          </Box>
        )}

        {selected === "projects" && (
          <Box>
            <Typography
              variant="h4"
              color="textPrimary"
              sx={{ my: 10, textAlign: "center" }}
            >
              Projects
            </Typography>
            <Box
              sx={{
                backgroundColor: "#f9f9ff",
                width: "100%",
                p: { xs: 4, sm: 6, md: 8, lg: 10 },
              }}
            >
              <Grid
                container
                spacing={4}
                sx={{ my: 4, justifyContent: "center" }}
              >
                {projectLoading && (
                  <Typography variant="h6">Loading projects...</Typography>
                )}

                {projectError && (
                  <Typography variant="h6" color="error">
                    Error: {projectError}
                  </Typography>
                )}

                {!projectLoading && !projectError && (
                  <>
                    {data.content?.heading &&
                      projectData.projects
                        .filter((item) =>
                          item.projectApplication.includes(
                            data.content?.heading
                          )
                        )
                        .map((item, index) => (
                          <ProjectCard key={index} data={item} />
                        ))}
                  </>
                )}

                {/* Optionally handle the case where there are no projects */}
                {!projectLoading &&
                  !projectError &&
                  projectData.projects.length === 0 && (
                    <Typography variant="h6">No projects available</Typography>
                  )}
              </Grid>
            </Box>
          </Box>
        )}

        {selected === "applications" && (
          <Box>
            <Typography
              variant="h4"
              color="textPrimary"
              sx={{ my: 10, textAlign: "center" }}
            >
              Related Applications
            </Typography>
            <Box
              sx={{
                backgroundColor: "#f9f9ff",
                width: "100%",
                p: { xs: 4, sm: 6, md: 8, lg: 10 },
              }}
            >
              <Grid
                container
                spacing={4}
                sx={{ my: 4, justifyContent: "center" }}
              >
                {applicationLoading && (
                  <Typography variant="h6">Loading applications...</Typography>
                )}

                {applicationError && (
                  <Typography variant="h6" color="error">
                    Error: {applicationError}
                  </Typography>
                )}

                {!applicationLoading && !applicationError && (
                  <>
                    {data.content?.heading &&
                      applicationData.applications
                        .filter(
                          (item) =>
                            item.content.heading !== data.content?.heading
                        )
                        .slice(0, 2)
                        .map((item, index) => (
                          <ApplicationCard key={index} data={item} />
                        ))}
                  </>
                )}

                {/* Optionally handle the case where there are no projects */}
                {!applicationLoading &&
                  !applicationError &&
                  applicationData.applications.length === 0 && (
                    <Typography variant="h6">No projects available</Typography>
                  )}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AppDesc;
