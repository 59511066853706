import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Chip,
  IconButton,
  Breadcrumbs,
  Link,
} from "@mui/material";
import {  useParams } from "react-router-dom";
import {
  Share as ShareIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";
import useFetchData from "./useFetchHook";
import { BlogCard } from "./BlogCard";

const BlogDescription = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetchData("blogs/blogData.json");
  const [blogData, setBlogData] = useState([]);
  const [blogDescription, setBlogDescription] = useState();

  useEffect(() => {
    if (data && data.blogs) {
      setBlogData(data.blogs.slice(0, 3));
     
    }
  }, [data]);

  const fetchBlog = () => {
    setBlogDescription(blogData.find(blog => blog.id === id));
    console.log(blogData.find(blog => blog.id === id));
  };

  useEffect(() => {
    fetchBlog();
    console.log(id);
  }, [id,blogData]);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  const handleCopy=()=>{
    const url = window.location.href; // Get the current URL
    if (navigator.share) {
      navigator.share({
        title: 'Check this out!',
        url: url,
      })
      .then(() => console.log('Successfully shared!'))
      .catch(err => console.error('Error sharing:', err));
    } else {
      alert('Sharing is not supported in your browser.');
    }
  }

  return (
    <>
      <Box bgcolor="white" width="100%">
        <Container maxWidth="lg" sx={{ pb: 8 }}>
          {/* Header Section */}
          <Box py={6} textAlign="center">
            <Breadcrumbs aria-label="breadcrumb" sx={{ pb: 8 }}>
              <Link underline="hover" color="inherit" href="/">
                Home
              </Link>
              <Link underline="hover" color="inherit" href="/blogs">
                Blogs
              </Link>
              <Link sx={{ color: "#FFA836", textDecoration: "none" }}>
                Blog {blogDescription?.id}
              </Link>
            </Breadcrumbs>
            <Typography variant="h5" color="text.primary" gutterBottom>
              {blogDescription?.blogHeading}
            </Typography>
            <Typography variant="subtitle1" color="orange">
              {blogDescription?.blogAuthor} • 19 Jan 2022
            </Typography>
          </Box>

          {/* Main Image */}
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
            }}
          >
            <Box
              component="img"
              src={`${blogDescription?.blogImages[0]?.mediaUrl}`}
              alt="blog Image"
              sx={{
                // width: "auto",
                width: { xs: "auto", sm: "auto", md: "100%", lg: "100%" },
                margin: "0%",
                height: { xs: "auto", sm: "400px", md: "400px", lg: "400px" },
              }}
            />
            <Typography
              variant="h5"
              color="text.secondary"
              sx={{
                mt: 2,
                padding: { xs: "5%", sm: "5%", md: "5% 10%", lg: "5% 10%" },
              }}
            >
              <div
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: blogDescription?.blogDescription,
                }}
              />
            </Typography>
          </Box>

          <Box textAlign="center" mb={4}>
            <Chip
              label="BLOGS"
              sx={{
                bgcolor: "#FFA836",
                color: "white",
                borderRadius: "36px",
                px: 3,
                py: 1,
                boxShadow: "0px 1px 2px #6951ff0d",
              }}
            />
          </Box>

          {/* Content Section */}
          <Grid container spacing={4} direction="column">
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary" paragraph>
                <div
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{
                    __html: blogDescription?.blogContent,
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              py: "2%",
            }}
          >
            <IconButton
            onClick={handleCopy}
            >
              <ShareIcon />
            </IconButton>
            <IconButton
             onClick={handleCopy}
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
             onClick={handleCopy}
            >
              <TwitterIcon />
            </IconButton>
          </Box>
        </Container>
      </Box>

      <Box sx={{ textAlign: "center", my: 4 }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", color: "#333" }}>
          Related Blogs
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ p: "5%", display: "flex", justifyContent: "space-evenly" }}
        >
          {blogData.slice(0, 3).map((data, index) => (
            <BlogCard key={index} data={data} />
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default BlogDescription;
