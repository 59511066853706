import React from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
  ListItem,
  ListItemText,
  Link,
  Breadcrumbs,
} from "@mui/material";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { Download } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import ApplicationCard from "./applicationCard";
import ProductCard from "./productCard";
import { useState } from "react";
import TaskIcon from "@mui/icons-material/Task";
import ModelCard from "./ModelCard";
import NewTestCarousal from "./NewTestCarousal.js";
import useFetchData from "./useFetchHook";

export const IndividualProduct = () => {
  const {
    data: applicationData,
    loading: applicationLoading,
    error: applicationError,
  } = useFetchData("applications/applicationData.json");
  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useFetchData("products/productData.json");

  const location = useLocation();
  const { data } = location.state || {};
  const [visibleIndex, setVisibleIndex] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [selected, setSelected] = useState("details");

  const handleIconClick = (buttonKey) => {
    setVisibleIndex((prevStates) => ({
      ...prevStates,
      [buttonKey]: !prevStates[buttonKey],
    }));
  };
  if (productLoading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (productError) {
    return (
      <Typography variant="h6" color="error">
        Error: {productError}
      </Typography>
    );
  }
  if (!productData || !productData.products) {
    return <Typography variant="h6">No services available</Typography>;
  }
  return (
    <>
      <Grid container spacing={4} sx={{ p: "4%", backgroundColor: "white" }}>
        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Roboto, Helvetica",
                color: "#98a2b3",
                fontSize: "18px",
              }}
            >
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/">
                    Home
                  </Link>
                  <Link underline="hover" color="inherit" href="/products">
                    Products
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    href={`/products/${data?.productCategory}`}
                  >
                    {data?.productCategory}
                  </Link>
                  <Typography sx={{ color: "#FFA836" }}>
                    {data?.productName}
                  </Typography>
                </Breadcrumbs>
              </div>

              <span style={{}}> </span>
            </Typography>
            <Box sx={{ my: 2 }}>
              <NewTestCarousal data={data?.productImages || []} />

              {/* <TestCarousal data={data?.productImages || []}/> */}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box sx={{ my: 5 }}>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Inter, Helvetica",
                  fontWeight: "bold",
                  color: "black",
                  borderBottom: "1.5px solid #e0e0e0",
                  pb: 4,
                }}
              >
                {data?.productName}
              </Typography>
              <Box sx={{ my: 2 }}>
                <span><u>Product Code : {data?.productCode}</u></span>
              </Box>

              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Roboto, Helvetica",
                  color: "#1a432e",
                  mt: 4,
                }}
              >
                {data?.productDescription}
                <br />
              </Typography>
            </Box>
            {/* <Box sx={{ borderRadius: "60px", border: "1px solid transparent" }}>
              <Button
                sx={{
                  borderRadius: "60px",
                  border: "none",
                  padding: "10px 64px",
                  backgroundColor: "#FFA836",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#FFA836",
                  },
                }}
              >
                Add To Cart
              </Button>
            </Box> */}
            <Box
              sx={{
                my: 4,
                px: { xs: 2, sm: 3, md: 4, lg: 6 }, // Horizontal padding for different screen sizes
                py: 1, // Vertical padding (top and bottom)
                borderRadius: "60px",
                border: "1px solid transparent",
                backgroundColor: "whitesmoke",
                display: "flex",
                justifyContent: "space-around", // Center buttons horizontally
                gap: 1, // Space between buttons
              }}
            >
              <Button
                sx={{
                  borderRadius: "60px",
                  border: "none",
                  padding: {
                    xs: "2px 24px", // Padding for extra-small screens
                    sm: "2px 32px", // Padding for small screens
                    md: "2px 48px", // Padding for medium screens
                    lg: "2px 64px", // Padding for large screens
                  },
                  backgroundColor:
                    selected === "details" ? "white" : "whitesmoke",
                  color: "black",
                  "&:hover": {
                    backgroundColor:
                      selected === "details" ? "white" : "whitesmoke",
                  },
                }}
                onClick={() => setSelected("details")}
              >
                Details
              </Button>
              <Button
                sx={{
                  borderRadius: "60px",
                  border: "none",
                  padding: {
                    xs: "4px 24px", // Padding for extra-small screens
                    sm: "6px 32px", // Padding for small screens
                    md: "6px 48px", // Padding for medium screens
                    lg: "6px 64px", // Padding for large screens
                  },
                  backgroundColor:
                    selected === "manuals" ? "white" : "whitesmoke",
                  color: "black",
                  "&:hover": {
                    backgroundColor:
                      selected === "manuals" ? "white" : "whitesmoke",
                  },
                }}
                onClick={() => setSelected("manuals")}
              >
                Data Manuals
              </Button>
            </Box>
            {selected === "details" && (
              <Box>
                <Box sx={{ mb: 2, pb: 2, borderBottom: "1.5px solid #e0e0e0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Description
                    </Typography>
                    <IconButton onClick={() => handleIconClick("first")}>
                      {visibleIndex.first ? (
                        <RemoveCircleOutlineRoundedIcon
                          sx={{ color: "#FFA836" }}
                        />
                      ) : (
                        <AddCircleOutlineRoundedIcon
                          sx={{ color: "#FFA836" }}
                        />
                      )}
                    </IconButton>
                  </Box>
                  {visibleIndex.first === true && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.productDescription
                            .split("\n")
                            .map(
                              (line) => `
                      <div class="bg-white-100 rounded flex p-3 h-full items-center">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-orange-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                        <span class="title-font font-medium">${line.replace(
                          /\t/g,
                          "&nbsp;&nbsp;&nbsp;&nbsp;"
                        )}</span>
                      </div>`
                            )
                            .join(""),
                        }}
                      />
                      {/* {data?.productDescription} */}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mb: 2, pb: 2, borderBottom: "1.5px solid #e0e0e0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Features
                    </Typography>
                    <IconButton onClick={() => handleIconClick("second")}>
                      {visibleIndex.second ? (
                        <RemoveCircleOutlineRoundedIcon
                          sx={{ color: "#FFA836" }}
                        />
                      ) : (
                        <AddCircleOutlineRoundedIcon
                          sx={{ color: "#FFA836" }}
                        />
                      )}
                    </IconButton>
                  </Box>
                  {visibleIndex.second === true && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.productHighlights
                            .split("\n")
                            .map(
                              (line) => `
                      <div class="bg-white-100 rounded flex p-3 h-full items-center">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-orange-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                        <span class="title-font font-medium">${line.replace(
                          /\t/g,
                          "&nbsp;&nbsp;&nbsp;&nbsp;"
                        )}</span>
                      </div>`
                            )
                            .join(""),
                        }}
                      />
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mb: 2, pb: 2, borderBottom: "1.5px solid #e0e0e0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Models
                    </Typography>
                    <IconButton onClick={() => handleIconClick("third")}>
                      {visibleIndex.third ? (
                        <RemoveCircleOutlineRoundedIcon
                          sx={{ color: "#FFA836" }}
                        />
                      ) : (
                        <AddCircleOutlineRoundedIcon
                          sx={{ color: "#FFA836" }}
                        />
                      )}
                    </IconButton>
                  </Box>
                  {visibleIndex.third === true && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      <Grid
                        container
                        columns={{ xs: 4, sm: 6, md: 6, lg: 6 }}
                        sx={{ display: "flex", justifyContent: "space-evenly" }}
                      >
                        {data.models[0]?.modelName &&
                        data.models?.length > 0 ? (
                          data.models.map((model, index) => (
                            <ModelCard data={model} key={index} />
                          ))
                        ) : (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <h2>No Models Available</h2>
                          </div>
                        )}
                      </Grid>
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
            {selected === "manuals" && (
              <Box sx={{ mb: 2, pb: 2, borderBottom: "1.5px solid #e0e0e0" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6" component="div">
                    Data Manuals
                  </Typography>
                  <IconButton onClick={() => handleIconClick("first")}>
                    {visibleIndex.first ? (
                      <RemoveCircleOutlineRoundedIcon
                        sx={{ color: "#FFA836" }}
                      />
                    ) : (
                      <AddCircleOutlineRoundedIcon sx={{ color: "#FFA836" }} />
                    )}
                  </IconButton>
                </Box>

                {visibleIndex.first === true && (
                  <Box>
                    {data.productDatasheets?.length > 0
                      ? data?.productDatasheets?.map((file, index) => (
                          <Box key={index}>
                            <ListItem>
                              <TaskIcon />
                              <ListItemText
                                primary={file.fileName}
                                sx={{ ml: 2 }}
                              />
                              <IconButton sx={{ mr: -2 ,color: "#FFA836"}}>
                                <a href={file.fileUrl} download={file.fileName}>
                                  <Download />
                                </a>
                              </IconButton>
                            </ListItem>
                          </Box>
                        ))
                      : "No Files Exist"}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "center", my: 4 }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", color: "#333" }}>
          Applications
        </Typography>
        <Grid container spacing={2} sx={{ p: "5%" }}>
          {applicationLoading && (
            <Typography variant="h6">Loading products...</Typography>
          )}

          {applicationError && (
            <Typography variant="h6" color="error">
              Error: {applicationError}
            </Typography>
          )}

          {!applicationLoading && !applicationError && (
            <>
              {data?.productApplication &&
                applicationData.applications
                  .filter((item) =>
                    data.productApplication.includes(item.content.heading)
                  )
                  .map((item, index) => (
                    <ApplicationCard key={index} data={item} />
                  ))}
            </>
          )}
          {/* Optionally handle the case where there are no projects */}
          {!applicationLoading &&
            !applicationError &&
            productData.products.length === 0 && (
              <Typography variant="h6">No projects available</Typography>
            )}
        </Grid>
      </Box>

      <Box sx={{ textAlign: "center", py: 4, backgroundColor: "white", py: 4 }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", color: "#333" }}>
          Related Products
        </Typography>
      </Box>
      <Box sx={{ p: 6, backgroundColor: "white" }}>
        <Grid container spacing={2} columns={{ xs: 2, sm: 6, md: 8, lg: 12 }}>
          {productLoading && (
            <Typography variant="h6">Loading products...</Typography>
          )}

          {productError && (
            <Typography variant="h6" color="error">
              Error: {productError}
            </Typography>
          )}

          {!productLoading && !productError && (
            <>
              {data?.productCategory &&
                productData.products
                  .filter(
                    (product) =>
                      product.productCategory === data.productCategory &&
                      product.productName !== data.productName
                  )
                  .map((item, index) => (
                    <ProductCard data={item} key={index} />
                  ))}
            </>
          )}

          {/* Optionally handle the case where there are no projects */}
          {!productLoading &&
            !productError &&
            productData.products.length === 0 && (
              <Typography variant="h6">No projects available</Typography>
            )}
        </Grid>
      </Box>
    </>
  );
};
