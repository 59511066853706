import React, { useState } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import "./animation.css";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import ClientInfoForm from "./TestForm";

const Boxc = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });

  return (
    <>
         <ClientInfoForm open={open} handleClose={handleClose} />
   

      <Box
        sx={{
          position: "relative",
          width: "100%",
          top: 0,
          left: 0,
          bgcolor: "rgba(255, 168, 54,0.2)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 1446,
            height: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: 2, sm: 4, md: 6 },
            py: { xs: 4, sm: 6 },
          }}
        >
          {/* Left Section */}
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              px: 2,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              ref={ref}
              className={inView ? "scale-in-left" : ""}
              variant="h1"
              sx={{
                fontFamily: "Inter-Bold, Helvetica",
                fontWeight: "bold",
                color: "#0d0b01",
                fontSize: { xs: "2rem", md: "4rem" },
                lineHeight: 1.2,
                pb: 3,
              }}
            >
              Do you have any Questions ?
            </Typography>
          </Box>

          {/* Right Section */}
          <Box
            ref={ref}
            className={inView ? "scale-in-left" : ""}
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Inter-Regular, Helvetica",
                  fontWeight: "normal",
                  color: "#5e6c84",
                  fontSize: { xs: "0.875rem", md: "1rem" },
                  mb: 4,
                  textAlign: { xs: "center", md: "left", lg: "left" },
                }}
              >
                HS controls instruments are suitable for virtually any type of
                environmental monitoring application, both portable and
                long-term, outdoors and indoors, ensuring accurate and reliable
                measurements of various physical quantities.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate("/contact")}
                sx={{
                  bgcolor: "#ff9900",
                  color: "white",
                  mx: "12px",
                  borderRadius: "50px",
                  px: 4,
                  py: 1.5,
                  fontFamily: "Inter-Regular, Helvetica",
                  fontWeight: "normal",
                  textTransform: "none",
                  "&:hover": {
                    bgcolor: "#e68a00",
                  },
                }}
              >
                Contact Us
              </Button>
              <Button
               onClick={handleClickOpen}
                variant="outlined"
                sx={{
                  borderColor: "#42526d",
                  color: "#42526d",
                  borderRadius: "50px",
                  mx: "12px",
                  px: 4,
                  py: 1.5,
                  fontFamily: "Inter-Regular, Helvetica",
                  fontWeight: "normal",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "#32424b",
                    bgcolor: "#f0f0f0",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Need Help ? 
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Boxc;
