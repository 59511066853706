import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import ProductCard from "./productCard";
import CategoryCard from "./categoryCard";
import { useParams } from "react-router-dom";
import useFetchData from "./useFetchHook";

export const ProductsPage = () => {
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useFetchData("categories/categoryData.json");
  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useFetchData("products/productData.json");
  const { params } = useParams();

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (categoryData && categoryData.categories) {
      setCategories(categoryData.categories);
    }
  }, [categoryData]);

  useEffect(() => {
    if (productData && productData.products) {
      // Filter products based on params
      if (params) {
        const filtered = productData.products.filter(
          (item) => item.productCategory === params
        );
        setFilteredProducts(filtered);
      } else {
        // If no params, reset to an empty array
        setFilteredProducts([]);
      }
    }
  }, [productData, params]);

  if (categoryLoading || productLoading)
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center",height:'100vh' }}
      >
        <img src="miscellaneousImages/loader.png" />
      </Box>
    );

  if (categoryError) {
    return (
      <Typography variant="h6" color="error">
        Error fetching categories: {categoryError}
      </Typography>
    );
  }

  if (productError) {
    return (
      <Typography variant="h6" color="error">
        Error fetching products: {productError}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: "100%",
        p: { xs: 4, sm: 6, md: 8, lg: 10 },
      }}
    >
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h2" sx={{ fontWeight: "bold", color: "#333" }}>
          Our Products
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#5e6c84",
            textAlign: "center",
            fontSize: "20px",
            my: 2,
            mx: "10%",
          }}
        >
          From cutting-edge technology to tailored solutions, each product is
          engineered to meet the demands of modern industries, ensuring
          reliability and sustainability.
        </Typography>
      </Box>
      <Grid container spacing={4} style={{ backgroundColor: "white" }}>
        {params ? (
          filteredProducts.length > 0 ? (
            filteredProducts.map((item, index) => (
              <ProductCard key={index} data={item} />
            ))
          ) : (
            <Typography variant="h6" color="error">
              No products found for this category.
            </Typography>
          )
        ) : (
          categories.map((category, index) => (
            <CategoryCard key={index} data={category} />
          ))
        )}
      </Grid>
    </Box>
  );
};
