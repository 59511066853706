import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { BlogCard } from "../components/BlogCard";
import useFetchData from "./useFetchHook";

const BlogMainPage = () => {
  const { data, loading, error } = useFetchData("blogs/blogData.json");
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    if (data && data.blogs) {
      setBlogData(data.blogs);
    }
  }, [data]);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        my={4}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "32px", sm: "36px", md: "56px", lg: "56px" },
            color: "black",
            fontFamily: "Inter-Bold, Helvetica",
            fontWeight: "bold",
          }}
        >
          Blogs
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#5e6c84",
            fontFamily: "Inter-Regular, Helvetica",
            mt: 2,
            fontSize:'20px',
            textAlign:'center',
          }}
        >
        Dive into our curated blog collection, featuring expert opinions and stories that engage and inform readers.
        </Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          my: 4,
          alignItems: "center",
          justifyContent: "space-between",
          p: "5%",
        }}
      >
        {blogData.map((item, index) => (
          <BlogCard data={item} key={index} />
        ))}
      </Grid>
    </>
  );
};

export default BlogMainPage;
