import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";

import IconButton from "@mui/material/IconButton";
import {
  ArrowForwardIosOutlined,
  ArrowBackIosOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import "./animation.css";

const NewTestCarousal = ({ data }) => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 5000); // Change testimonial every 2 seconds

    return () => clearInterval(interval); // Clear interval on unmount
  }, [data.length]);

  return (
    <>
      <Grid
        item
        sx={{
          width: "100%",
          height:'50vh',
          // height: { xs: "300px", sm: "300px",md:"300px",lg:"300px" },
          padding: { xs: "0px", sm: "0% 0%" },
          position: "relative",
          overflow: "hidden",
          // Ensure relative positioning for child absolute positioning
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            height: "100%",
            position: "relative", // Make sure the content is positioned relative to this container
          }}
        >
          {/* Background Image with Filter */}
          <Grid
            item
            sx={{
              position: "absolute",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              aspectRatio: "16/9",
              width: "100%",
              objectFit:'contain',
              backgroundImage: `url('${data[currentIndex]?.mediaUrl}')`,
            }}
          />

          {/* Buttons */}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}
            sx={{
              position: "absolute",
              bottom: 10,
              left: "0%",
              transform: "translate(-50%, 0)",
              zIndex: 1,
            }}
          >
            <Grid item>
              <IconButton
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #c2c7d0",
                  backgroundColor:'white'
                }}
                onClick={handleNext}
              >
                <ArrowBackIosOutlined sx={{ color: "orange" }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #c2c7d0",
                   backgroundColor:'white'
                }}
                onClick={handlePrevious}
              >
                <ArrowForwardIosOutlined sx={{ color: "orange" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default NewTestCarousal;
