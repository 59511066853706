import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link} from "react-router-dom";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#000000",
        py: 4,
        position: "relative",
        color: "white",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <Grid>
              <img
                src="/websiteLogo/HS-logo.png"
                alt="Hs Control & Systems"
                style={{ width: "154px", height: "50px" }}
              />
              <Box
                sx={{
                  display: "flex",
                  mt: "8%",
                  gap: 1,
                  position: "relative",
                  "& img": { width: "34px", height: "34px" },
                }}
              >
                <LinkedInIcon
                  onClick={() =>
                    (window.location.href =
                      "https://www.linkedin.com/company/hs-control-systems/posts/?feedView=all")
                  }
                />
                <XIcon
                  onClick={() =>
                    (window.location.href =
                      "https://www.facebook.com/hscontrolsystems")
                  }
                />
                <FacebookIcon
                  onClick={() =>
                    (window.location.href =
                      "https://www.facebook.com/hscontrolsystems")
                  }
                />
              </Box>
            </Grid>
          </Grid>
          {/* About Section */}
          <Grid item xs={12} md={4} lg={3}>
            <Typography variant="h6" gutterBottom>
              About
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              <Link to="/"> Home</Link>
              <br />
              <Link to="/services"> Services</Link>
              <br />
              <Link to="/products"> Products</Link>
              <br />
              <Link to="/applications"> Applications</Link>
            </Typography>
          </Grid>

          {/* Community Section */}
          <Grid item xs={12} md={4} lg={3}>
            <Typography variant="h6" gutterBottom>
              Community
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
             
              <Link to="/blogs"> Blog</Link>
              <br />
              <Link to="/projects"> Projects</Link>
              <br />
              <Link to="/contact"> Join Us</Link>
              <br />
              {/* <Link to="/events"> Events</Link> */}
             
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box sx={{ height: "240px" }}>
              <iframe
                width="100%"
                height="100%"
                frameborder="0"
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3505.3928075001018!2d77.27198597549697!3d28.52791067572211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDMxJzQwLjUiTiA3N8KwMTYnMjguNCJF!5e0!3m2!1sen!2sin!4v1727506501151!5m2!1sen!2sin"
              ></iframe>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              <LocationOnIcon /> ADDRESS
              <br />
              HS Control & Systems; C-19, Second Floor, DDA Sheds, Okhla Phase
              I, Okhla Industrial Estate, New Delhi – 110020
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              <MailOutlineIcon /> EMAIL
              <br />
              info@hscontrolsystems.com
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              <PhoneIcon /> PHONE
              <br />
              +91-9354833470
            </Typography>
          </Grid>
          {/* Divider */}
          <Grid item xs={12} sx={{ my: 2 }}>
            {" "}
            <Box
              sx={{
                width: "100%",
                height: "1px",
                backgroundColor: "white",
              }}
            />
          </Grid>

          {/* Last Row: Footer Links */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">
              ©2024 HS CONTROL & SYSTEMS. All rights reserved
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography variant="body2">
                <a style={{ color: "white", textDecoration: "none" }}>
                  Privacy & Policy
                </a>
              </Typography>
              <Typography variant="body2">
                <a style={{ color: "white", textDecoration: "none" }}>
                  Terms & Conditions
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Footer;
