import { Grid, Box, IconButton, Typography } from "@mui/material";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";



const CategoryCard = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = async (value) => {
    navigate(`/products/${value}`);
  };

  return (
    <>
      <Grid item xs={6} sm={4} md={3} lg={3}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            boxShadow: "0 8px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",

            transition: "box-shadow 0.3s ease", // Smooth transition for the shadow
            "&:hover": {
              boxShadow:
                "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
            },
          }}
        >
          <Box
            component="div"
            sx={{
              backgroundImage: `url(${data.media[0]?.mediaUrl})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              aspectRatio: "16/9",
              width: "100%", // or any specific width
              height: "auto",
              overflow: "hidden",
            }}
          />
          <div
            style={{
              flexGrow: 1,
              backgroundColor: "#FFFFFF",
              padding: "12px",
            }}
          >
            <Typography variant="h6" gutterBottom>
              {data?.categoryName}
            </Typography>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 2,
              backgroundColor: "white",
            }}
          >
            <Typography
              variant="body2"
              sx={{ flexGrow: 1, color: "#FFA836", fontWeight: "700" }}
            >
              Read more
            </Typography>
            <IconButton>
              <ArrowForwardIosOutlined
                onClick={()=>handleClick(data?.categoryName)}
                style={{ color: "#FFA836" }}
              />
            </IconButton>
          </Box>
        </div>
      </Grid>
    </>
  );
};
export default CategoryCard;
