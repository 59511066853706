import { Typography, Grid, Box, IconButton } from "@mui/material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { useNavigate } from "react-router-dom";
const ApplicationCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box
          sx={{
            backgroundColor: "#F3F3F3",
            borderRadius: "45px",

            border: "1px solid #191a23",
            boxShadow: "0px 5px 0px #191a23",
            p: 5,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
            }}
          >
            <Box
              sx={{
                borderRadius: "7px",
                px: 1,
                py: 4,
                width: "30%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter-Bold, Helvetica",
                  fontWeight: 700,
                  color: "#000000",
                  display: "inline-block",
                  fontSize: "21px",
                  letterSpacing: "0px",
                  lineHeight: "1.5",
                }}
              >
                {data.content.heading}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "210px",
                height: "170px",
                mt: { xs: -2, md: 4, lg: 4 },
                mb: { xs: 2, md: 0, lg: 0 },
                ml: { xs: -3, md: 0, lg: 0 },
              }}
            >
              <img
                src={`${data.content.media[0].mediaUrl}`}
                alt="Sample"
                style={{
                  width: "100%",
                  height: "100%",
                  // objectFit: "cover",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <IconButton
              sx={{
                backgroundColor: "#FFA836",
                color: "white",
                borderRadius: "20.5px",
                p: 1,
              }}
            >
              <ArrowOutwardOutlinedIcon
                onClick={() => navigate("/application-description", { state: { data }, })}
              />
            </IconButton>
            <Typography
              sx={{
                fontFamily: "Space Grotesk, Helvetica",
                fontWeight: 400,
                color: "#000000",
                fontSize: "18px",
                letterSpacing: "0px",
                lineHeight: "1.5",
              }}
            >
              Learn more
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
export default ApplicationCard;
