import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "./animation.css";

const ProjectCard = ({ data, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });
  const navigate = useNavigate();
  return (
    <>
      <Box
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        key={index}
        sx={{
          display: "flex",
          flexDirection: index % 2 === 0 ? "row" : "row-reverse",
          justifyContent: "space-between",
          py: "5%",
          overflow: "hidden",
          borderBottom: "1px solid grey",
          flexDirection: {
            xs: "column", // Stack vertically on small screens
            sm: "column", // Stack vertically on larger small screens
            md: index % 2 === 0 ? "row" : "row-reverse", // Alternating direction for medium screens and up
            lg: index % 2 === 0 ? "row" : "row-reverse", // Alternating direction for large screens and up
          },
        }}
      >
        {/* Main Image and Content */}
        <Grid
          ref={ref}
          className={inView ? "slide-in-left" : ""}
          item
          xs={12}
          sm={12}
          md={5}
          lg={4}
          sx={{ position: "relative", my: 3 }}
        >
          <img
            src={`${data.projectImages[0]?.mediaUrl}`}
            alt="Project Images"
            style={{
              objectFit: "contain",
              height: "100%",
            }}
          />
        </Grid>

        {/* Side Content */}
        <Grid item xs={12} sm={12} md={5} lg={6}>
          <Box
            ref={ref}
            className={inView ? "slide-in-right" : ""}
            sx={{
              my: {
                xs: 0,
                sm: 8,
                md: 8,
                lg: 12,
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: "#42526d",
                mb: 2,
              }}
            >
              {data.projectName}
            </Typography>
            <Typography variant="body1" sx={{ color: "#5e6c84", mb: 4 }}>
              {data.projectDescription}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "50px",
                padding: "10px 20px",
                mb: 2,
                backgroundColor: "#FFA836",
              }}
              onClick={() =>
                navigate("/project-description", { state: { data } })
              }
            >
              Learn More
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
export default ProjectCard;
