import React from "react";
import BlogMainPage from "../components/BlogMainPage";

const BlogPage = () => {
  return (
    <>
    <BlogMainPage/>
    </>
  );
};

export default BlogPage;
