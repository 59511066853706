import { useEffect, useRef } from "react";
import { Grid, Box, Typography,Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();

  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((err) => {
        console.log(err);
      });
    }
    return () => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    };
  }, []);

  return (
    <>
      <Box component="section" sx={{ position: "relative", p: 0 }}>
        {/* Video */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <video
            src="/website_videos/home_video.mp4"
            muted
            autoPlay
            loop
            style={{
              width: "100%",
              objectFit: "contain",
              filter: "brightness(0.40)", // Adjust the brightness here
            }}
          />
        </Box>

        {/* Overlay Content */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "8%",
            color: "white", // Ensure text is visible on the video
          }}
        >
          <Grid
            container
            spacing={1}
            columns={{ xs: 2, sm: 6, md: 12, lg: 12 }}
          >
            <Grid item xs={2} sm={3} md={4} lg={4}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: "14px", sm: "21px", md: "30px", lg: "42px" },
                  mb: { xs: 1, sm: 2, md: 3, lg: 2 },
                  textAlign: "left",
                  fontStyle: "Black",
                }}
              >
                Environmental Monitoring{" "}
                <span style={{ color: "#FFA836" }}>Solutions</span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "9px", sm: "16px", md: "22px", lg: "22px" },
                  textAlign: "left",
                  pl: { xs: 0, sm: 0, md: 0, lg: 1 },
                  // letterSpacing: 1,
                  color: "#FFA836",
                }}
              >
                Professional systems and instruments for measuring environmental
                physical parameters
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  mt: { xs: 2, sm: 2, md: 4, lg: 4 }, // Responsive margin top
                  ml: { xs: 0, sm: 0, md: 0, lg: 1 }, // Responsive margin left
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "#FFA836",
                    color: "white",
                    fontSize:{xs:'9px',sm:'12px',md:'16px',lg:'18px'},
                    px: { xs: 1, sm: 2, md: 2, lg: 2 }, // Responsive padding
                  }}
                  onClick={() => navigate("/products")}
                >
                  Know More
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Hero;
