import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Chip,
  Box,
  Stack,
} from "@mui/material";
import { useForm } from "@formspree/react";

const ClientInfoForm = ({ open, handleClose }) => {
  const [selectedServices, setSelectedServices] = useState([]);

  const handleServiceChange = (event) => {
    setSelectedServices(event.target.value);
  };
  const [state, handleSubmit] = useForm("xwpednkj");
  if (state.succeeded) {
    return (
      <Stack direction="row" spacing={1} sx={{ margin: "0% 20%" }}>
        <Chip label="Form Submit Successfully " />
      </Stack>
    );
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Please Fill the Form</DialogTitle>
      <DialogContent sx={{ backgroundColor: "#fff", padding: "20px" }}>
        <TextField
          label="Name"
          name="name"
          fullWidth
          sx={{ mb: 2 }}
          id="name"
          variant="outlined"
        />
        <TextField
          label="Email"
          name="email"
          fullWidth
          sx={{ mb: 2 }}
          id="email"
          variant="outlined"
        />
        <TextField
          label="Mobile"
          name="mobile"
          fullWidth
          sx={{ mb: 2 }}
          id="mobile"
          variant="outlined"
        />
        <TextField
          label="City"
          name="city"
          fullWidth
          sx={{ mb: 2 }}
          id="city"
          variant="outlined"
        />
        <TextField
          label="Zip Code"
          name="zipCode"
          fullWidth
          sx={{ mb: 2 }}
          id="zipcode"
          variant="outlined"
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="services-label">Select Service</InputLabel>
          <Select
            labelId="services-label"
            multiple
            id="serviceValue"
            name="serviceValue"
            value={selectedServices}
            onChange={handleServiceChange}
            renderValue={(selected) => (
              <Box>
                {selected.map((value) => (
                  <Chip key={value} label={value} sx={{ margin: "2px" }} />
                ))}
              </Box>
            )}
          >
            {["Sensors", "Consultation", "Solutions", "Services"].map(
              (service) => (
                <MenuItem key={service} value={service}>
                  {service}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <TextField
          label="Describe Project"
          name="projectDescription"
          fullWidth
          multiline
          rows={4}
          sx={{ mb: 2 }}
          id="projectdescription"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "black" }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          sx={{
            backgroundColor: "#ff9800",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#e68900",
            },
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientInfoForm;
