import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ApplicationCard from "./applicationCard";
import "./animation.css";
import useFetchData from "./useFetchHook";

export const FigmaApplicationPage = () => {
  const {
    data: applicationData,
    loading,
    error,
  } = useFetchData("applications/applicationData.json");

  const [applications, setApplications] = useState([]);
  useEffect(() => {
    if (applicationData && applicationData.applications) {
      setApplications(applicationData.applications);
    }
  }, [applicationData]);

  if (loading)
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center",height:'100vh' }}
      >
        <img src="miscellaneousImages/loader.png" />
      </Box>
    );
  if (error) return <p>Error: {error}</p>;

  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        py={4}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "32px", sm: "36px", md: "46px", lg: "52px" },
            color: "black",
            fontFamily: "Inter-Bold, Helvetica",
            fontWeight: "bold",
          }}
        >
          Applications
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#5e6c84",
            fontFamily: "Inter-Regular, Helvetica",
            mt: 2,
            fontSize:'20px',
            textAlign:'center',
          }}
        >
          Explore our suite of applications that cater to diverse needs,
          empowering world with powerful tools for complex
          challenges.
        </Typography>
      </Grid>
      <Box sx={{ p: "5%" }}>
        <Grid container spacing={2}>
          {applications.map((item, index) => (
            <ApplicationCard key={index} data={item} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
