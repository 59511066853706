import { useInView } from "react-intersection-observer";
import { useRef,useEffect } from "react";
import "./animation.css";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";

const HomeProduct = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((err) => {
        console.log(err);
      });
    }
    return () => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    };
  }, []);
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });
  return (
    <Box sx={{ px: 8, py: 2, backgroundColor: "white" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Box
            ref={ref}
            sx={{
              position: "relative",
              width: "100%",
              p: { xs: "0% 0%", sm: "0% 0%", md: "0% 10%", lg: "5% 10%" },
              height: "auto",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",

              "& img": {
                width: "100%",
                transition: "transform 0.5s",
                transform: inView ? "translateX(0)" : "translateX(-20px)",
              },
            }}
            // className={inView ? "slide-in-left" : ""}
          >
            <video
              src="/website_videos/about_video"
              controls
              loop
              style={{
                width: "100%",
                borderRadius: "12px",
                objectFit: "contain",
                filter: "brightness(0.95)", // Adjust the brightness here
              }}
            />
            {/* <img
              alt="image"
              src="/home_images/home_image.jpeg"
              style={{ aspectRatio: 4 / 3, borderRadius: "18px",   boxShadow:
                "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px", }}
            /> */}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            opacity: inView ? 1 : 0,
            transition: "opacity 0.5s",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // border:'1px solid black'
          }}
        >
          <Box ref={ref} className={inView ? "slide-in-right" : ""}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", mb: 1, color: "black" }}
            >
              Explore Our Products
            </Typography>
            <Typography
              variant="body1"
              sx={{ mb: 2, color: "black" }}
            ></Typography>
            <Box sx={{ mb: 2, textAlign: "justify" }}>
              <Typography variant="body1" sx={{ color: "black" }}>
                Across ice sheets… over mountains, valleys, and through solar
                farms and wind-powered landscapes… At HS Control, we capture the
                pulse of the planet with precision.
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                sx={{ color: "black", textAlign: "justify" }}
              >
                Our advanced sensors deliver real-time environmental
                data—anywhere, anytime. Because “One accurate measurement is
                worth a thousand expert opinions.” *With HS Control, every
                decision is grounded in precision.
              </Typography>
            </Box>
            <Button
              sx={{
                borderRadius: "50px",
                px: 4,
                color: "white",
                backgroundColor: "#FFA836",
                "&:hover": {
                  backgroundColor: "#FFA836",
                  opacity: 1,
                },
              }}
            >
              <Link to="/products"> See Products</Link>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default HomeProduct;
