// MUI
import React from "react";
import {
  Typography,
  Button,
  Box,
  Container,
  Grid,
  Divider,
  Link,
  Breadcrumbs,
  IconButton,
} from "@mui/material";
import { RemoveCircleOutline, AddCircleOutline } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import useFetchData from "./useFetchHook";
import ProjectCard from "./FigmaProjectCard";
import ProductCard from "./productCard";
import ApplicationCard from "./applicationCard";
import NewTestCarousal from "./NewTestCarousal";

const ProjectDesc = () => {
  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useFetchData("projects/projectData.json");
  const {
    data: applicationData,
    loading: applicationLoading,
    error: applicationError,
  } = useFetchData("applications/applicationData.json");
  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useFetchData("products/productData.json");
  const location = useLocation();
  const { data } = location.state || {};
  const [selected, setSelected] = useState("products");

  const [visibleIndex, setVisibleIndex] = useState({
    first: false,
    second: false,
    third: false,
  });

  const handleIconClick = (buttonKey) => {
    setVisibleIndex((prevStates) => ({
      ...prevStates,
      [buttonKey]: !prevStates[buttonKey],
    }));
  };
  if (projectLoading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (projectError) {
    return (
      <Typography variant="h6" color="error">
        Error: {projectError}
      </Typography>
    );
  }
  if (!projectData || !projectData.projects) {
    return <Typography variant="h6">No services available</Typography>;
  }

  return (
    <Box
      sx={{
        bgcolor: "white",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <Container sx={{ mt: 8 }}>
          <Typography variant="body2" color="textSecondary">
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                  Home
                </Link>
                <Link underline="hover" color="inherit" href="/projects">
                  Projects
                </Link>
                <Typography sx={{ color: "#FFA836" }}>
                  {data.projectName}
                </Typography>
              </Breadcrumbs>
            </div>
          </Typography>
          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={{ my: 2 }}>
                {/* <TestCarousal data={data?.projectImages || []} /> */}
                <NewTestCarousal data={data?.projectImages || []} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="h4" color="textPrimary">
                {data.projectName}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1" color="textPrimary">
                {/* {data.content.description} */}
                {data.projectDescription}
                {/* <Typography
                  variant="body1"
                  color="primary"
                  component="span"
                  sx={{ textDecoration: "underline" }}
                >
                  Read more...
                </Typography> */}
              </Typography>
              <Box sx={{ mt: 4 }}>
                <Box sx={{ mb: 2, pb: 2, borderBottom: "1.5px solid #e0e0e0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Objective
                    </Typography>
                    <IconButton onClick={() => handleIconClick("first")}>
                      {visibleIndex.first ? (
                        <RemoveCircleOutline sx={{ color: "#FFA836" }} />
                      ) : (
                        <AddCircleOutline sx={{ color: "#FFA836" }} />
                      )}
                    </IconButton>
                  </Box>
                  {visibleIndex.first === true && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.projectObjective
                            .split("\n")
                            .map(
                              (line) => `
                      <div class="bg-white-100 rounded flex p-3 h-full items-center">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-orange-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                        <span class="title-font font-medium">${line.replace(
                          /\t/g,
                          "&nbsp;&nbsp;&nbsp;&nbsp;"
                        )}</span>
                      </div>`
                            )
                            .join(""),
                        }}
                      />
                      {/* {data.projectObjective} */}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mb: 2, pb: 2, borderBottom: "1.5px solid #e0e0e0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Solution
                    </Typography>
                    <IconButton onClick={() => handleIconClick("second")}>
                      {visibleIndex.second ? (
                        <RemoveCircleOutline sx={{ color: "#FFA836" }} />
                      ) : (
                        <AddCircleOutline sx={{ color: "#FFA836" }} />
                      )}
                    </IconButton>
                  </Box>
                  {visibleIndex.second === true && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.projectSolution
                            .split("\n")
                            .map(
                              (line) => `
                      <div class="bg-white-100 rounded flex p-3 h-full items-center">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-orange-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                        <span class="title-font font-medium">${line.replace(
                          /\t/g,
                          "&nbsp;&nbsp;&nbsp;&nbsp;"
                        )}</span>
                      </div>`
                            )
                            .join(""),
                        }}
                      />
                      {/* {data.projectSolution} */}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            mx: "10%",
            my: 4,
            px: { xs: 2, sm: 3, md: 4, lg: 6 }, // Horizontal padding for different screen sizes
            py: 1, // Vertical padding (top and bottom)
            borderRadius: "60px",
            border: "1px solid transparent",
            backgroundColor: "whitesmoke",
            display: "flex",
            justifyContent: "space-around", // Center buttons horizontally
            gap: 1, // Space between buttons
          }}
        >
          <Button
            sx={{
              borderRadius: "60px",
              border: "none",
              padding: {
                xs: "2px 24px",
                sm: "2px 32px",
                md: "2px 48px",
                lg: "2px 64px",
              },
              backgroundColor: selected === "products" ? "white" : "whitesmoke",
              color: "black",
              "&:hover": {
                backgroundColor:
                  selected === "products" ? "white" : "whitesmoke",
              },
            }}
            onClick={() => setSelected("products")}
          >
            Products
          </Button>
          <Button
            sx={{
              borderRadius: "60px",
              border: "none",
              padding: {
                xs: "2px 24px",
                sm: "2px 32px",
                md: "2px 48px",
                lg: "2px 64px",
              },
              backgroundColor: selected === "projects" ? "white" : "whitesmoke",
              color: "black",
              "&:hover": {
                backgroundColor:
                  selected === "projects" ? "white" : "whitesmoke",
              },
            }}
            onClick={() => setSelected("projects")}
          >
            Projects
          </Button>
          <Button
            sx={{
              borderRadius: "60px",
              border: "none",
              padding: {
                xs: "4px 24px", // Padding for extra-small screens
                sm: "6px 32px", // Padding for small screens
                md: "6px 48px", // Padding for medium screens
                lg: "6px 64px", // Padding for large screens
              },
              backgroundColor:
                selected === "applications" ? "white" : "whitesmoke",
              color: "black",
              "&:hover": {
                backgroundColor:
                  selected === "applications" ? "white" : "whitesmoke",
              },
            }}
            onClick={() => setSelected("applications")}
          >
            More Applications
          </Button>
        </Box>

        {selected === "products" && (
          <Box>
            <Typography
              variant="h4"
              color="textPrimary"
              sx={{ my: 10, textAlign: "center" }}
            >
              Products Used in project
            </Typography>
            <Box
              sx={{
                backgroundColor: "#f9f9ff",
                width: "100%",
                p: { xs: 4, sm: 6, md: 8, lg: 10 },
              }}
            >
              <Grid
                container
                spacing={4}
                sx={{ my: 4, justifyContent: "center" }}
              >
                {productLoading && (
                  <Typography variant="h6">Loading products...</Typography>
                )}

                {productError && (
                  <Typography variant="h6" color="error">
                    Error: {productError}
                  </Typography>
                )}

                {!productLoading && !productError && (
                  <>
                    {data?.projectName &&
                      productData.products
                        .filter((item) =>
                          data?.productsUsedId.includes(item.id)
                        )
                        .map((item, index) => (
                          <ProductCard key={index} data={item} />
                        ))}
                  </>
                )}
                {/* Optionally handle the case where there are no projects */}
                {!productLoading &&
                  !productError &&
                  productData.products.length === 0 && (
                    <Typography variant="h6">No projects available</Typography>
                  )}
              </Grid>
            </Box>
          </Box>
        )}

        {selected === "projects" && (
          <Box>
            <Typography
              variant="h4"
              color="textPrimary"
              sx={{ my: 10, textAlign: "center" }}
            >
              Related Projects
            </Typography>
            <Box
              sx={{
                backgroundColor: "#f9f9ff",
                width: "100%",
                p: { xs: 4, sm: 6, md: 8, lg: 10 },
              }}
            >
              <Grid
                container
                spacing={4}
                sx={{ my: 4, justifyContent: "center" }}
              >
                {projectLoading && (
                  <Typography variant="h6">Loading projects...</Typography>
                )}

                {projectError && (
                  <Typography variant="h6" color="error">
                    Error: {projectError}
                  </Typography>
                )}

                {!projectLoading && !projectError && (
                  <>
                    {data?.projectName &&
                      projectData.projects
                        .filter((item) =>
                          data.projectApplication.some((app) =>
                            item.projectApplication.includes(app)
                          )
                        )
                        .map((item, index) => (
                          <ProjectCard key={index} data={item} />
                        ))}
                  </>
                )}

                {/* Optionally handle the case where there are no projects */}
                {!projectLoading &&
                  !projectError &&
                  projectData.projects.length === 0 && (
                    <Typography variant="h6">No projects available</Typography>
                  )}
              </Grid>
            </Box>
          </Box>
        )}

        {selected === "applications" && (
          <Box>
            <Typography
              variant="h4"
              color="textPrimary"
              sx={{ my: 10, textAlign: "center" }}
            >
              Related Applications
            </Typography>
            <Box
              sx={{
                backgroundColor: "#f9f9ff",
                width: "100%",
                p: { xs: 4, sm: 6, md: 8, lg: 10 },
              }}
            >
              <Grid container spacing={4} sx={{ p: "5%" }}>
                {applicationLoading && (
                  <Typography variant="h6">Loading products...</Typography>
                )}

                {applicationError && (
                  <Typography variant="h6" color="error">
                    Error: {applicationError}
                  </Typography>
                )}

                {!applicationLoading && !applicationError && (
                  <>
                    {data?.projectApplication &&
                      applicationData.applications
                        .filter((item) =>
                          data?.projectApplication.includes(
                            item.content.heading
                          )
                        )
                        .map((item, index) => (
                          <ApplicationCard key={index} data={item} />
                        ))}
                  </>
                )}
                {/* Optionally handle the case where there are no projects */}
                {!applicationLoading &&
                  !applicationError &&
                  productData.products.length === 0 && (
                    <Typography variant="h6">No projects available</Typography>
                  )}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProjectDesc;
